//
// features.scss
//
@media (min-width: 200px) and (max-width: $mobile-width) {
  .support-container {
    border-color: transparent !important;
    padding: 0px !important;
    .core-value-item {
      p {
        min-height: 10px !important;
      }
    }
  }
  .partner-item {
    div {
      padding: 0px !important;
    }
    max-height: auto !important;
    min-height: auto !important;
    img {
      width: 100%;
      height: auto;
    }
  }
}
.features-content {
  .nav {
    .nav-link {
      padding: 30px;
      &.active {
        background: $white !important;
        box-shadow: $shadow;
      }
    }
  }
}

#features {
  .support-container {
    border: 2px solid $primary;
    border-radius: 25px;
    padding: 20px;
    // padding-bottom: 0px;
    // padding-top: 45px;
  }
}

#partner {
  background-color: #222222;
  //   background: transparent linear-gradient(180deg, #000000 0%, #2c2c2c 100%) 0%
  //     0% no-repeat padding-box;

  .partner-item {
    padding: 1rem;
    max-height: 160px;

    min-height: 160px;
  }
}

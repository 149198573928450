//
// general.scss
//
body {
  overflow-y: overlay;
}
a {
  text-decoration: none !important;
  outline: none;
  color: $primary;
}
body {
  font-family: $font-primary;
  color: $dark;
  background: $white;
}
p {
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-secondary;
}

body {
  background-color: $bg !important;
}

.z-1 {
  z-index: 10 !important;
}
.z-2 {
  z-index: 20 !important;
}
.z-3 {
  z-index: 30 !important;
}

.ff-raleway * {
  font-family: Raleway !important;
  letter-spacing: 0.06em;
}

.ff-reservation * {
  font-family: $font-primary !important;
}

.ff-reservation {
  font-family: $font-primary !important;
}

.text-nowrap {
  white-space: nowrap;
}

.icon-link {
  transition: all 0.2s;
  cursor: pointer;
  svg {
    transition: all 0.2s;
    fill: white;
  }
}
.call-us {
  transition: all 0.2s;
  cursor: pointer;

  span {
    transition: all 0.2s;
    font-weight: normal;

    position: relative;
  }
  svg {
    transition: all 0.2s;
    position: absolute;
    left: 0;
    top: 2px;
    fill: black;
    // fill: white;
    // visibility:
    // ;
    // display: none;
    opacity: 0;
  }
  &:hover {
    span {
      transform: translateX(10px);
      font-weight: bold;
    }
    svg {
      transform: translateX(-18px);
      opacity: 1;
    }
  }
}

.icon-link.gray {
  transition: all 0.2s;
  cursor: pointer;
  svg {
    transition: all 0.2s;
    fill: #90999f;
  }
}
.icon-link[data-disabled="true"] {
  cursor: unset;
  svg {
    transition: all 0.2s;
    fill: gray !important;
  }
}

.icon-link:not([data-disabled="true"]):hover {
  svg {
    fill: $primary;
  }
  color: $primary !important;
}

.page {
  margin-top: 4vw;
}
@media (min-width: 200px) and (max-width: 768px) {
  .Copyright {
    white-space: normal !important ;
    text-align: center;
    width: 100%;
    padding: 0px 5px;
    bottom: 12px !important;

    font-size:  x-small !important;
  }

  .page {
    margin-top: 15vw;
  }
  .img-main-section {
    height: 21vh !important;
    width: 94vw !important;
    .overlay-text {
      width: 100% !important;
      height: 100% !important;
      left: -28% !important;
      top: 0px !important;
    }
    .image-title {
      margin-left: 10px !important;
      font: normal normal 900 34px/55px reservation !important;
    }
  }
  .we-do {
    span {
      padding-left: 1em;
    }
    .overlay-text {
      left: -28% !important;
      top: 0px !important;
      width: 100% !important;
      height: 100% !important;
    }
    .image-title {
      padding-left: 10px !important;
      margin-left: 0px !important;
    }
  }
}

.Copyright {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2px;
  font-size: 14px;
}

.img-main-section {
  position: relative;
  .overlay {
    position: absolute;
    left: 0%;
    border-radius: 200px;
    //   opacity: 0.4;
    width: 100%;
    mix-blend-mode: multiply;
    height: 100%;
    opacity: 0.8;
    background: #fed17e 0% 0% no-repeat padding-box;
  }
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 200px;
  height: 215px;

  // padding: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-items: flex-start;
  .image-title {
    transition: all 0.6s linear;

    position: relative;

    .overlay-text {
      position: absolute;
      left: -49%;
      top: -31px;
      // opacity: 0.4;
      // background-color: red;
      transition: all 0.6s linear;
      transform: rotate(0deg);
      width: 150%;
      height: 150%;
      // background-image: url("../images/Component 7 – 1.svg");
      // mix-blend-mode: multiply;
      opacity: 0.8;
      // background: #fed17e 0% 0% no-repeat padding-box;
    }
    padding: 30px;
    margin: 10px;
    margin-left: 30px;
    min-width: 200px;
    color: $white;
    letter-spacing: 0px;
    min-width: 215px;
    text-shadow: 0px 3px 6px #00000029;
    font: normal normal 900 40px/55px reservation;
  }
}

.image-title:hover {
  .overlay-text {
    transform: rotate(25deg);
  }
}

.div-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.br-5 {
  border-radius: 5px !important;
}
.br-25 {
  border-radius: 25px !important;
}
.text-bold {
  font-weight: bold !important;
}
.fw-bold {
  font-weight: bold !important;
}
.fw-bolder {
  font-weight: bolder !important;
}
.fwb {
  font-family: "Reservation-bold" !important;
  font-weight: bolder !important;
}
// @media (min-width: 1400px) {
//   .container,
//   .container-sm,
//   .container-md,
//   .container-lg,
//   .container-xl,
//   .container-xxl {
//     max-width: 1460px;
//   }
// }

@media (min-width: 1400px) {
  .container {
    max-width: 1250px !important;
  }
}


#page-not-found{

flex-direction: column;
  .main-box{
    width: 40vw;
    border-radius: 25vw;
    border:3px solid $primary;
    >div {
      color:white;
      width: 100%;
      flex-direction: column;
      padding:20px;
      
      span:first-child() {
        font-size: 3em;

      }
      span:second-child() {
        font-size: medium;
        ;

      }
    }
  }
  @media (min-width: 200px) and (max-width: 768px) {
    .main-box{
    
      width: 90vw;
    }
  }
}




@media (min-width: 200px) {
  .textJustify {
    text-align: justify!important ;

     *{
         text-align: justify!important ;
      }
  }
}
.form-control:focus {
  box-shadow: none;
  background-color: $light;
  border-color: $primary;
}

.form-control {
  padding: 8px 10px;
  font-size: 14px;
  height: 40px;
  background-color: rgba($dark, 0.01);
  border: 1px solid rgba($muted, 0.3);
  &::placeholder {
    color: lighten($muted, 6%);
    font-size: 14px;
  }
}

.error {
  margin: 8px 0px;
  display: none;
  color: $danger;
}

#ajaxsuccess {
  font-size: 16px;
  width: 100%;
  display: none;
  clear: both;
  margin: 8px 0px;
}

#news-page {
  padding-top: 70px;
  .news-card:nth-child(1) {
    margin-top: 0rem;
  }

  .news-card {
    //  margin: 0.3rem !important;
    width: 100%;
    padding: 10px;
    height: 350px;
    display: flex;
    justify-content: center;
    align-content: end;
    align-items: end;
    position: relative;
    .ff-raleway.text-white * {
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
    .text-description {
      height: auto;
      width: 100%;
      line-height: 20px;
      box-shadow: 0px 5px 10px #00000080;
      border-radius: 15px;
      overflow: hidden;

      // border: 3px $primary solid;

      padding: 15px;
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      margin: 10px;
      color: $white;
      background-color: #000000ab;
      div {
        display: -webkit-box;
        display: -moz-box;
        -webkit-box-orient: vertical;
        -moz-box-orient: horizontal !important;
        -ms-box-orient: vertical;
        box-orient: vertical;
        -webkit-line-clamp: 3;
        -moz-line-clamp: 3;
        -ms-line-clamp: 3;
        line-clamp: 3;
        overflow: hidden;
        max-height: 4.5em;
        line-height: 1.8em;
      }
    }
    .img {
      transition: background-size 0.4s;
      &:hover {
        background-size: 115% auto;
      }
      // background: url(mountain.jpg);
      background-repeat: no-repeat;
      // background-size: cover;
      background-size: 100% auto;
      // background-position: center;
      // background-size: auto;
      border-radius: 25px;
      background-position: center;
      height: 350px;
      // padding: 10px;
      display: flex;
      align-items: end;
      width: 100%;
      justify-items: flex-start;
      .image-title {
        // transform: matrix(0, -1, 1, 0, -52, 0);

        position: absolute;
        transform: rotate(180deg);
        font-weight: bold;
        color: #ffffff;
        left: -0.5em;
        writing-mode: tb-rl;

        text-shadow: 0px 5px 5px #000000a8;
        font-size: 2.6em !important;
        font-weight: bold;
        color: $white;
        // width: 100%;
        // background-color: #000000ab;
        // min-width: 200px;
      }
    }

    #overlay-background-news {
      &:hover {
        z-index: 10;
        transform: scale(1.1);
      }
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      background-size: cover;
      background-position: center;
      transition: all 0.4s;

      // display: none;
    }
    &:hover {
      #overlay-background-news {
        z-index: 10;
        transform: scale(1.1);
      }
    }
  }

  @media (min-width: 200px) and (max-width: 768px) {
    .card-content-container.open {
      top: 22px !important;
    }
    .container-description {
      .row {
        > div {
          padding: 0px !important;
          .text-description {
            margin-right: 0px !important;
          }
        }
      }
    }

    .links-container {
      margin-top: 7rem;
      .icon-link {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
      }
    }

    .news-card {
      height: 280px;
      .img {
        border-radius: 15px !important;
        height: 280px;
        .img-fluid {
          // background-size: auto 100%;
          background-size: cover;
        }
        width: 100% !important;
      }

      .text-description {
        width: 90%;
        border-radius: 15px !important;
        .text-primary {
          margin: 0pc;
        }
        // height: 47%;
        height: auto;

        margin-left: auto;
        margin-right: auto;
        left: 0 !important;
        right: 0 !important;
        p {
          line-height: 1.2;
        }
        // -webkit-line-clamp: 3 !important;
        // -moz-line-clamp: 3 !important;
        // -ms-line-clamp: 3 !important;
        // line-clamp: 3 !important;

        > div {
          -webkit-line-clamp: 3;
          -moz-line-clamp: 3;
          -ms-line-clamp: 3;
          line-clamp: 3;
          overflow: hidden;
          max-height: 3.5em;
        }
      }
    }
  }

  .news-card-full {
    //  margin: 0.3rem !important;
    width: 100%;
    // padding: 10  px;
    background: black;
    will-change: opacity;
    min-height: calc(100vh - 90px);

    position: relative;
    .container-description {
      padding-top: 13rem;
      .links-container {
        // position: fixed;
        // margin-left: 3rem !important;
      }
    }
    .text-description {
      height: auto;

      line-height: 20px;
      overflow: hidden;
      padding: 15px;
      border-radius: 15px;
      margin: 10px;
      color: $white;

      background: transparent
        linear-gradient(180deg, #000000ba 0%, #313131 100%) 0% 0% no-repeat
        padding-box 0% no-repeat padding-box;
      background: transparent
        linear-gradient(180deg, #000000cc 0%, #313131 100%) 0% 0% no-repeat
        padding-box;
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      min-height: 30rem;
    }
    .news-image {
      position: absolute;
      top: 0px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 300px;
      width: 100%;
      .image-title {
        display: none;
      }
    }
  }

  // xxxxxxxxxxxxxxxxxx
  .card-content-container {
    scroll-behavior: smooth;
    width: 100%;
    height: 100%;
    position: relative;
    // pointer-events: none;
  }

  .card-content-container.open::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  .card-content-container.open {
    top: 40px;
    left: 0;
    right: 0;
    z-index: 50;
    padding: 40px 0;
    padding-bottom: 0px;
    position: fixed;
    overflow-y: scroll;
    overscroll-behavior: contain;
    right: 0;
    bottom: 0;
    left: 0;
  }
  // /////////////////////
}
body.noscroll {
  overflow: hidden;
}
#career-page {
  padding-top: 70px;
  .img-main-section {
    background-position: center !important;
  }

  @media (min-width: 200px) and (max-width: 768px) {
    .cv-form-font {
      .input-group-text {
        border-bottom-right-radius: 5px !important;
        border-top-right-radius: 5px !important;
      }
      .show-input {
        border-bottom-right-radius: 0px !important;
        border-top-right-radius: 0px !important;
      }
      .btn-primary {
        border-radius: 5px !important;
      }
      input,
      textarea {
        border-radius: 5px !important;
        // display: none;
        padding-left: 1px !important;
        padding-top: 9px !important;
        font-size: 12px !important;
      }
    }
    .vacancy-item-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      > div {
        flex: 0 0 auto;
        width: 50%;
        padding-right: 5px;
        padding-bottom: 5px;
      }
    }
    .vacancy-item {
      border-radius: 5px !important;
      padding: 10px 10px !important;
      min-width: auto !important;
      width: 100% !important;
      border-radius: 10px;
      flex-direction: column;
      justify-content: center !important;
      text-align: start;
      align-items: start;
      height: 100% !important;
    }
    .posistion-item-container {
      margin-right: 5px;
    }
    .slick-list {
      margin-right: -0px !important;
    }
    .slick-slide {
      // margin: 0 5px;
      > div {
        // min-width: 30vw;
      }
    }

    .posistion-item {
      div {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      
      }
    } 
    /* the slides */
 
  }

  /* the parent */
  .slick-list {
    // margin: 0 -5px;
  }
  .vacancy-item-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    > div {
      flex: 0 0 auto;
      width: 50%;
      padding-right: 5px;
      padding-bottom: 5px;
    }
  }
  .posistion-item {
    min-width: auto;

    div {
      font-size: $fz-normal !important;
      padding: 10px 10px   !important;   
      padding-bottom: 5px !important;
    }
  }
  .vacancy-item {
    min-width: 300px;
    height: 70px;
    padding: 10px 15px;
    cursor: pointer;
    transition: all 0.4s;
    background: #00000000 0% 0% no-repeat padding-box;
    border: 1px solid #8c8b8b;
    // border: 1px solid #ffffff;
    border-radius: 10px;
    &.active {
      border: 1px solid $primary !important;
      .title {
        color: $primary !important;
      }
    }
  }
  .vacancy-item:hover {
    border: 1px solid $white;
    // border: 1px solid #ffffff;
  }
}

//
// loader.scss
//

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  // top: 100px;
  right: 0;
  bottom: 0;
  img {
    -webkit-animation: rotate-center 5s linear infinite;
    animation: rotate-center 5s linear infinite;
  }
  z-index: 990;

  .overly {
    height: 100%;
    width: 100%;
    background: #000000;

    div {
      opacity: 0.3;
      height: 100%;
      width: 100%;
      // background: linear-gradient(to top left, $primary 0%, #000000 100%);
    }
  }
}

#preloader #status {
  position: absolute;
  // left: 50%;
  // top: 50%;
  margin: auto;
  // -webkit-transform: translateY(-50%);
  // transform: translateY(-50%);
  top: 52%;
  left: calc(50%);
  transform: translate(-50%, -50%);
}

.spinner {
  // width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  margin-top: 2rem;
  background-color: #fed17ea6;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

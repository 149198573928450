.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flex-0 {
  flex: 0 !important;
}
.flex-1 {
  flex: 1 !important;
}

.h-95 {
  height: 95% !important;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
}
.flexEnd {
  display: flex;
  align-items: center;
  justify-content: end;
  align-content: center;
}
.flexStart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
}

.fw-500 {
  font-weight: 500 !important;
}

.fz-sm {
  font-size: clamp(0.88rem, 0.674rem + 0.0624vw, 0.75rem) !important;
  line-height: 1.4em;
}
.fz-m {
  font-size: clamp(0.79rem, 0.7217rem + 0.1258vw, 0.875rem) !important;
}
@media (min-width: 200px) and (max-width: 768px) {
  .fz-m {
    font-size: 0.9em !important;
  }
}

.fz-lg {
  font-size: clamp(0.875rem, 0.8467rem + 0.1258vw, 1rem) !important;
}
.fw-normal {
  font-weight: normal !important;
}
.fz-xl {
  font-size: clamp(0.875rem, 0.8184rem + 0.2516vw, 1.125rem) !important;
}
.fz-xxl {
  font-size: clamp(1rem, 0.9434rem + 0.2516vw, 1.25rem) !important;
}
.fz-xxxl {
  font-size: clamp(1.25rem, 1.1934rem + 0.2516vw, 1.5rem) !important;
}
.fz-4xl {
  font-size: clamp(1.375rem, 1.3184rem + 0.2516vw, 1.625rem) !important;
}
.fz-5xl {
  font-size: clamp(1.625rem, 1.5684rem + 0.2516vw, 1.875rem) !important;
}
.fz-6xl {
  font-size: clamp(1.875rem, 1.592rem + 1.2579vw, 3.125rem) !important;
}

.w-80 {
  width: 80% !important;
}
.text-right {
  text-align: right;
}

html {
  scrollbar-face-color: #646464;
  scrollbar-base-color: #646464;
  scrollbar-3dlight-color: #646464;
  scrollbar-highlight-color: #646464;
  scrollbar-track-color: #000;
  scrollbar-arrow-color: #000;
  scrollbar-shadow-color: #646464;
}

::-webkit-scrollbar {
  width: 8px;
  height: 3px;
}

::-webkit-scrollbar-track {
  background-color: #646464;
}
::-webkit-scrollbar-track-piece {
  background-color: #000;
}
::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #666;
  border-radius: 3px;
}
:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #fed17e !important ;
  --toastify-color-success:  #fed17e !important ;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
}
.Toastify__toast{

  border: 2px solid #262626 !important;
}
//
// Hero
//

.about-img {
  padding: 40px;
}

#about {
  .main-image-container {
    img {
      height: auto;
      padding: 10px;
      width: 100%;
    }
  }

  .row-image-container {
    img {
      height: auto;
      padding: 10px;
      width: 100%;
      min-width: 254px;
      max-width: 254px;
    }
    .text {
      width: 200px;
    }
  }

  .card-image img {
    filter: grayscale(100%);
  }
  .card-image:hover {
    img {
      filter: none;
    }
  }
  .image-overlay {
    cursor: pointer;
    position: absolute;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    top: 20px;
    left: 0px;
    border: 3px solid #fed17e;
    border-radius: 25px;
    transition: all 0.3s;
  }
  .image-overlay:hover {
    top: 25px;
    left: -8px;
  }
  // Responsive

  @media (min-width: 200px) and (max-width: 768px) {
    .card-image img {
      filter: none !important;
    }
    .about-containt {
      font-size: $fz-normal !important;
      p {
        line-height: 20px !important;
      }
    }
    .main-image-container {
      img {
        padding: 10px 25px;
        filter: none;
      }
    }
    .first {
      .image-overlay {
        width: calc(100% - 45px);
        height: calc(100% - 21px);
        top: 22px;
        left: 13px;
      }
    }
    // .image-overlay:not(:first) {
    //   width: calc(100% - 45px);
    //   height: calc(100% - 21px);
    //   top: 111px;
    //   left: 13px;
    // }

    .about-container {
      min-height: 400px !important;
    }
    .core-value-item {
      img {
        width: 40px !important;
      }
      font-size: $fz-normal !important;
    }
    #core-value {
      max-height: 345px !important;
      height: 50vh !important;
    }
    .about-item {
      #title {
        // display: none;
        color: $primary !important;
      }
    }
  }
  .about-container {
    min-height: 400px !important;
  }
  .about-item {
    cursor: pointer;
    border: 3px solid transparent;
    border-radius: 25px;
    padding: 10px;
    // padding-bottom: 30px;
    transition: all 0.2s;
    background-color: #2e2d2c;
    margin: 15px;
    margin-left: 0px;
    margin-right: 0px;
    font-size: 1em;
    #title {
      transition: all 0.2s;
    }
  }

  .about-item:hover {
    #title {
      color: $primary !important;
      transform: scale(1.2);
    }

    // transform: scale(1, 1.1);
    margin: 0px 0px !important;

    padding: 25px 10px;

    border: 3px solid #fed17e;
  }

  #core-value {
    max-height: 530px;
    height: 90vh;
    position: relative;
    .title {
      position: absolute;
      top: 0%;
      left: 0%;
      width: 100%;
      height: 100%;
    }
    .main-img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      opacity: 0.2;
      left: 0;
    }
    .core-value-item {
      img {
      }
    }
  }
}

#main-image-container {
  > div {
    // background-image: url("~/public/images/bussiness/main-image.png");
    transition: background-image 0.05s linear;
    // background-color: red;
    // background-image: url("../images/hero-1-bg-img.png");
  }
  > div:hover {
    // background-image: url("~/public/images/bussiness/main-image2.png");
    // background-image: url("../images/hero-1-bg-img.png");
  }
}

//
// blog.scss
//
#what-we-do-section {
  padding-top: 70px;
  @media (min-width: 200px) and (max-width: 768px) {
    .item-list-ul {
      list-style: none;
      padding: 0px;
      li {
        margin-bottom: 10px;
        white-space: normal !important;
        text-decoration: underline;

        text-underline-offset: 2px;

        svg {
          display: none;
        }
      }
    }
    .what-we-do-card:not(:first-of-type) {
      margin-top: -180px; // should be the same with => #2233
    }
    .what-we-do-card {
      height: auto !important;
      flex-direction: column;
      .img {
        .image-title {
          top: 1.14em !important;
          left: 50% !important;
          text-align: center !important;
          writing-mode: unset !important;
          right: unset !important;
          width: 100%;
          font-size: 2em !important;

          transform: translate(-50%, -0%) !important;
        }
      }
      .img-fluid {
        height: 250px !important;
      }
      .text-description {
        display: block !important;
        max-height: 100% !important;
        margin: 0px !important;
        position: relative !important;
        // top: 220px;
        width: 90% !important;
        top: -150px; // #2233
        max-height: 100% !important;
      }
    }
  }

  .what-we-do-card:nth-child(even) {
    .img {
      margin-left: auto;
      .image-title {
        left: auto;
        writing-mode: vertical-rl;
        text-align: center;
        right: -0.5em;
        transform: rotate(0deg);
      }
    }
    .text-description {
      left: 0px;
    }
  }
  .what-we-do-card {
    width: 100%;
    padding: 10px;
    height: 400px;
    display: flex;
    align-items: center;
    position: relative;
    .text-description {
      position: absolute;
      right: 0px;
      // min-height: 50%;
      max-height: 78%;
      width: 60%;

      line-height: 20px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
      -ms-box-orient: vertical;
      box-orient: vertical;
      -webkit-line-clamp: 20;
      -moz-line-clamp: 20;
      -ms-line-clamp: 20;
      line-clamp: 20;
      overflow: hidden;

      box-shadow: 0px 5px 10px #00000080;
      border-radius: 10px;

      border: 1px $primary solid;

      padding: 15px;
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      margin: 10px;
      color: $white;
      background-color: #000000ab;
    }
    .img {
      // background: url(mountain.jpg);
      background-repeat: no-repeat;
      background-size: cover !important;
      background-position: center;
      background-size: auto;
      border-radius: 25px;
      height: 350px;
      // padding: 10px;
      display: flex;
      align-items: center;
      width: 100%;
      justify-items: flex-start;
      .image-title {
        // transform: matrix(0, -1, 1, 0, -52, 0);
        text-align: center;
        position: absolute;
        transform: rotate(180deg);
        font-weight: bold;
        color: #ffffff;
        left: -0.5em;
        writing-mode: tb-rl;
        // margin-top: -2rem;
        text-shadow: 0px 5px 5px #000000a8;
        font-size: 2.6em;
        font-weight: bold;
        color: $white;
      }
    }
  }

  @media (min-width: 200px) and (max-width: 768px) {
    .what-we-do-card {
      .img {
        width: 100% !important;
      }
      .text-description {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        left: 0 !important;
        right: 0 !important;
      }
    }
  }
}
.blog-box {
  .blog-img {
    max-width: 150px;
  }
  .blog-box-content {
    margin-bottom: 30px;
  }
}

//
// variables.scss
//
@font-face {
  font-family: "Reservation";
  src: url("../fonts/Reservation/Reservation Wide Regular.ttf")
    format("truetype");
  font-display: fallback;
}
@font-face {
  font-family: "Reservation";
  src: url("../fonts/Reservation/Reservation Wide Bold.ttf") format("truetype");
  font-weight: bold;
  font-display: fallback;
}
@font-face {
  font-family: "Reservation-bold";
  src: url("../fonts/Reservation/ReservationWide-Blk.otf") format("truetype");
  font-weight: bolder;
  font-display: fallback;
}

@import url("https://fonts.googleapis.com/css?family=Muli:400,500,600,700|Rubik:400,500,700&display=swap");

// Color variables
$white: #ffffff;
$black: #000000;

$primary: #fed17e;
$secondary: #2e2d2c;
$success: #2dd6b7;
$info: #1097bd;
$warning: #ffbb13;
$danger: #f43958;
$dark: #2c2c2c;
$muted: #9b9bae;
$light: #fbfbfd;
$bg: black;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "dark": $dark,
  "muted": $muted,
  "light": $light,
  "black": $black,
  "white": $white,
);

$gradient: linear-gradient(to right, $primary 0%, rgba($primary, 0.6) 100%);

// Shadow
$shadow: 2px 2px 12px -2px rgba($black, 0.08);

// Font
$font-primary: "Reservation", Muli;
$font-secondary: "Reservation", sans-serif;

$mobile-width: 600px;
$hd-width: 1380px;

$fz-normal: 0.9em;
$fz-lg: 1.1em;

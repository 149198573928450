//
// services.scss
//

.service-box {
  transition: all 0.5s;
  .service-icon-bg {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    .service-box-icon {
      display: flex;
      height: 100%;
      align-items: center;
      filter: brightness(0.55);
      transition: all 0.5s;
    }
  }
  .service-title,
  .read-more {
    color: $dark;
  }
  .service-subtitle {
    color: $muted;
  }
  &:hover {
    background: $primary;
    border-color: $primary;
    .service-box-icon {
      filter: brightness(1);
    }
    .service-title,
    .read-more,
    .service-subtitle,
    .service-box-icon {
      transition: all 0.5s;
    }
    .service-title,
    .read-more {
      color: $white;
    }
    .service-subtitle {
      color: rgba($white, 0.5);
    }
  }
}

#our-location {
  min-height: 44rem;
  height: 100vh;
  max-height: 44rem;
  position: relative;
  .overlay1 {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: transparent
      url("~/public/images/joshua-sortino-LqKhnDzSF-8-unsplash.png") 0% 0%
      no-repeat padding-box;
    background-size: cover;
    background-position: center;
  }

  .box-container {
    box-shadow: 0px 5px 10px #00000080;
    border-radius: 25px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: #000000cf;
    padding: 20px;
  }

  .side-title {
    writing-mode: tb-rl;
    transform: rotate(180deg);
  }
  .left-side-height {
    height: 30rem;
    overflow: hidden;
    overflow-y: auto;
  }
  .left-side-height::-webkit-scrollbar-thumb {
    visibility: hidden;
  }
  .top-side-height {
    height: 7rem;
  }
  .location-img {
    width: 100%; //35rem;
    height: auto;
  }
  .top-left-side {
    width: 100%;
    position: relative;
    .overlay {
      position: absolute;
      width: 100%;
      top: 0;
      right: 0;
    }
  }

  @media (min-width: 1923px) {
    .top-left-side {
      .overlay {
        position: absolute;
        width: 100%;
      }
    }
    max-height: 33rem !important;
    min-height: 44.4rem !important;
  }

  @media (min-width: 200px) and (max-width: 768px) {
    max-height: fit-content !important;
    height: auto !important;
    padding-bottom:  50px !important;

    .top-side-height {
      margin-bottom: 0px !important;
      height: 100%;
 
    }
    .top-left-side {

      width: 100%;
      padding: 1em;
      margin-left: auto;
      margin-right: auto;
      // margin-bottom: 0.5em;
      height: 100%;
      .overlay {
        width: 100%;
      }
    }

    .side-title {
      writing-mode: unset;
      transform: rotate(0deg);
      font-size: 1em;
    }
    .box-container {
      border-radius: 10px;
    }
    #cities-list {
      flex-direction: row !important;
      flex-wrap: wrap;
      padding: 0.5rem;
      justify-content: unset !important;
      h6 {
        text-align: center !important;
        width: 33%;
        margin: 0.6em 0px;
        font-size: 0.9em !important;
      }
    }
    .left-side-height {
      margin: 1rem 0px;
      height: auto !important;
      overflow: hidden;
      // overflow-y: auto;
    }
  }
}

#bg-first-section {
  overflow: hidden !important;

  .main-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-size: auto;
  }

  position: absolute;
  width: 100%;
  height: 100%;
  left: 45px;
  top: 0;
  img {
    transition: all 1s linear;
    width: 15rem;
    height: inherit;
  }
}
#image1-container {
  min-height: 10rem !important;
}
#image1-container:hover {
  img {
    transform: rotate(45deg);
  }
}

.bio {
  font: normal normal 900 2.8em/60px Reservation !important;
  font-weight: bolder;
  // white-space: nowrap;
  span {
    font: normal normal normal 30px/67px Reservation;
  }
}
#main-image-container {
  // height: 50hv;
  .main-images {
    margin-top: -5vh !important;
    .main-images-item {
      width: 100%;
    }
  }
}
.mouse-scroll-icon {
  width: 100%;
  cursor: pointer;
  position: absolute;
  // bottom: -16vh;
  left: 0;
  z-index: 10;
  right: 0;
  // width: 40px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  bottom: 7vh;
}
.mouse-scroll-icon-mobile {
  // display: none;
  width: 100%;
  bottom: 2vh;
  cursor: pointer;
  position: absolute;
  left: 0;
  z-index: 10;
  right: 0;
  height: 30px;
  margin-left: auto;
  margin-right: auto;
}
.main-image-border-inside {
  width: 110%;
  height: 103%;
  left: -5%;
  top: -1.3%;
  // overflow: hidden;
  position: absolute;
  // background-color: rgba(255, 0, 0, 0);
  border-radius: 20px;
  // transform: scale(1.2);
  border: 8px $primary solid;
}

@media (min-width: $mobile-width) and (max-width: $hd-width) {
  .bio {
    line-height: 2.2em;
    text-align: left;
    font: normal normal 900 2.3em Reservation !important;
    span {
      font: normal normal normal 0.8em Reservation !important;
    }
  }
}

@media (min-width: 450px) and (max-width: 768px) {
  .mouse-scroll-icon {
    // display: none;
  }
  #main-image-container {
    height: 40vh;
    margin-top: 12vh;
    .main-images {
      margin-top: 0px;
      padding: 0px 10px;
      padding-right: 15px;
      div {
        padding: 2px !important;
        margin-left: 5px;
        margin-right: 5px;
      }
      .main-images-item {
        width: 100%;
        height: auto;
        // height: 100% !important;
      }
    }
  }

  .bio {
    line-height: 2.2em;
    text-align: center;
    font: normal normal 900 2em Reservation !important;
    width: 70%;
    span {
      font: normal normal normal 0.8em Reservation !important;
    }
  }
  #bg-first-section img {
    width: 10rem !important;
  }
  #main-button {
    text-align: center;
  }
  #bg-first-section {
    left: 0px;
    text-align: center;
  }
  .show-all-news-bar {
    .cont {
      min-height: auto !important;
    }
    .slider-show-all-item {
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      flex-direction: column;
      position: relative;
      transform: none;
    }
  }
}
@media (min-width: 200px) and (max-width: 449px) {
  .mouse-scroll-icon {
    // display: none;
  }

  .mouse-scroll-icon-mobile {
    // display: block;
  }
  .main-images-contianer {
    max-height: 630px !important;
    height: 100%;
    > .row {
      flex-direction: column;
      justify-content: space-around !important;
    }
  }

  #main-image-container {
    height: 67vw;

    display: flex;
    justify-content: center;
    align-items: flex-end;

    .main-images {
      margin-top: 0px;
      padding: 0px 10px;
      padding-right: 15px;
      div {
        padding: 2px !important;
        margin-left: 5px;
        margin-right: 5px;
      }
      .main-images-item {
        width: 118%;
        height: auto;
        // height: 100% !important;
      }
    }
  }

  #main-button {
    text-align: center;
  }
  .bio {
    line-height: 2.2em;
    text-align: center;
    // width: 80%;
    width: 100%;
    font: normal normal 900 2em Reservation !important;
    font-family: "Reservation-bold" !important;
    span {
      font: normal normal normal 0.8em Reservation !important;
    }
  }
  #bg-first-section img {
    width: 10rem !important;
  }
  #bg-first-section {
    left: 0px;
    text-align: center;
  }
}

//
// components.scss
//

.btn {
  border-radius: 10px;
  padding: 12px 30px;
  font-size: 14px !important;
  transition: all 0.3s;

  &:focus {
    box-shadow: none !important;
  }
  &:hover {
    // transform: translateY(-2px);
    opacity: 0.9;
    font-weight: 500 !important;
    transition: font-weight 0.1s;
  }
  &.btn-lg {
    padding: 8px 20px !important;
    font-size: 16px !important;
  }
  &.btn-primary {
    color: $dark !important;
  }
  &.btn-sm {
    padding: 8px 16px !important;
    font-size: 13px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 32px;
  }

  @each $color, $value in $theme-colors {
    &.btn-#{$color} {
      background-color: $value;
      border: none;
      color: $white;
      box-shadow: 0 4px 20px rgba($value, 0.15);
      &:hover,
      &:focus,
      &.focus,
      &:active,
      &.active {
        // box-shadow: 0 3px 10px rgba($value, 0.5);
        color: $dark !important;
      }
    }
    &.white-hover {
      &:hover,
      &:focus,
      &.focus,
      &:active {
        background-color: white !important;
        color: black !important;
      }
    }
    &.btn-secondary {
      &:hover,
      &:focus,
      &.focus,
      &:active,
      &.active {
        color: $white;
      }
    }

    &.btn-outline-#{$color} {
      border: 1px solid #{$value} !important;
      color: #{$value} !important;
      background-color: transparent;
      &:hover,
      &:focus,
      &.focus,
      &:active,
      &.active {
        background-color: $primary !important; //#{$value} !important;
        border: 1px solid $primary !important; //#{$value} !important;
        // box-shadow: 0 3px 10px rgba($value, 0.5);
        color: $black !important; //$white !important;
      }
    }
  }
  &.btn-light {
    background-color: $light;
    border: 1px solid $light;
    color: $dark !important;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: lighten($light, 3%);
      border: 1px solid lighten($light, 3%);
      box-shadow: 0 0 0 2px rgba($light, 0.08);
      color: $dark !important;
    }
  }

  &.btn-dark {
    background-color: $secondary;
    border: 1px solid $secondary;
    color: $white !important;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: lighten($light, 3%);
      border: 1px solid lighten($light, 3%);
      box-shadow: 0 0 0 2px rgba($light, 0.08);
      color: $dark !important;
    }
  }

  &.btn-outline-blue {
    border: 1px solid $white;
    background-color: transparent;
    transition: all 0.3 !important;

    color: $white !important;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: #0a66c2;
      border: 1px solid #0a66c2;
      color: $white !important;
      font-weight: 700 !important;
      border: 1px solid transparent;
    }
  }
  &.btn-outline-dark {
    border: 1px solid $light;
    color: $black !important;
    background-color: $dark;
    box-shadow: none;

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: $light;
      border: 1px solid $light;
      color: $black !important;
      font-weight: 900 !important;
    }
  }
  &.btn-outline-light {
    border: 1px solid $light;
    color: $black !important;
    background-color: $dark;
    font-weight: 900 !important;

    box-shadow: none;

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: $light;
      border: 1px solid $light;
      color: $black !important;
      font-weight: 900 !important;
    }
  }
  &.btn-outline-white {
    border: 1px solid $light;
    color: $white !important;
    background-color: transparent;
    font-weight: 900 !important;

    box-shadow: none;

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: transparent !important;
      border: 1px solid $light;
      color: $primary !important;
      font-weight: 900 !important;
    }
  }
}
.form-control {
  // background-color: transparent !important;
  background-color: #09090985 !important;
  padding-left: 5px;
  color: $white !important;
  text-indent: 10px !important;
}
.form-control:focus {
  box-shadow: 0px 3px 17px 0px #c2b50280 !important;
}

.form-control::placeholder {
  /* Firefox, Chrome, Opera */
  padding-left: 5px;
}
// Badge
.badge {
  padding: 6px 16px;
  font-weight: 400;
  &.badge-primary {
    background: rgba($primary, 0.1);
    box-shadow: 0 1px 2px rgba($primary, 0.5);
    color: $primary;
  }
}

.busniess-card {
  // width: 500px;
  padding: 10px;
  height: 350px;
  /* UI Properties */
  // background: transparent url("img/dan-meyers-IQVFVH0ajag-unsplash.png") 0% 0%
  //   no-repeat padding-box;

  .text-description {
    height: 60px;
    width: 100%;
    line-height: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    box-orient: vertical;
    -webkit-line-clamp: 3;
    -moz-line-clamp: 3;
    -ms-line-clamp: 3;
    line-clamp: 3;
    overflow: hidden;
  }
  .image-container {
    transition: all 0.3s ease-out;

    position: relative;
    overflow: hidden;
    border-radius: 25px;

    .image-title {
      position: absolute;

      top: 50%;
      left: 0%;
      transform: translate(-0%, -50%) scale(1);
      button {
        width: 0;
        height: 0;
        overflow: hidden;
        white-space: nowrap;
        transform: scale(0);
      }

      box-shadow: 0px 5px 10px #00000080;
      border-radius: 10px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-left: 3px $primary solid;
      // transform: scale(1) !important;

      padding: 15px;
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      margin: 10px;
      color: $white;
      background-color: #000000ab;
      min-width: 200px;
    }
  }
  .img {
    overflow: hidden;
    // background: url(mountain.jpg);
    background-repeat: no-repeat;
    // background-size: cover;
    background-position: center;
    background-size: cover;
    border-radius: 25px;
    transform: scale(1);

    transition: transform 0.2s linear;
    height: 200px;
    // padding: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-items: flex-start;
  }
  .image-container:hover {
    .img {
      transform: scale(1.2);
      // background-size: 150% !important;
    }
    .image-title {
      button {
        // display: block;
        height: 35px;
        width: 150px;
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}

@media (min-width: 200px) and (max-width: $mobile-width) {
  .what-we-do-page-section{
    margin-bottom: -160px;

  }
  #business-slider {
    width: 100%;
    // position: relative;
    // max-width: 110%;
  }

  .show-all-news-bar {
    .cont {
      min-height: auto !important;
    }
    .slider-show-all-item {
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      flex-direction: column;
      position: relative;
      transform: none;
    }
  }

  .busniess-card {
    .img {
      height: 160px;
    }
    .image-container .image-title {
      top: 45%;
    }
  }
  .slick-track .slick-slide > div {
    margin-right: 0px !important;
  }
  #clients {
    .item-list-ul {
      list-style: none;
      padding: 0px;
      li {
        margin-bottom: 10px;
      }
    }
  }
}
#clients {
  overflow: hidden;
  // #business-slider {
  // width: 100vw;
  // position: absolute;
  // left: 10px;
  // max-width: 120%;
  // }

  .item-list-li {
  }
}

.news-bar-card {
  // width: 30vw;
  // padding: 10px;
  height: 320px;
  .image-title {
    cursor: pointer;
    transition: all 0.3s ease;
    border: 1px transparent solid;
    .xtitle {
      transition: all 0.3s ease;
    }
  }
  .image-title:hover {
    border-color: $primary;
    .xtitle {
      color: $primary;
    }
  }

  .text-description {
    height: 40px;
    width: 100%;
    line-height: 20px;
    // word-break: break-all;
    // display: -webkit-box;
    // -webkit-box-orient: vertical;
    // -moz-box-orient: vertical;
    // -ms-box-orient: vertical;
    // box-orient: vertical;
    // -webkit-line-clamp: 2;
    // -moz-line-clamp: 2;
    // -ms-line-clamp: 2;
    overflow: hidden;
    div {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
      -ms-box-orient: vertical;
      box-orient: vertical;
      -webkit-line-clamp: 2;
      -moz-line-clamp: 2;
      -ms-line-clamp: 2;
      line-clamp: 2;
      overflow: hidden;
    }
  }

  .img {
    // background: url(mountain.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    // background-position: center;
    // background-size: auto;
    border-radius: 15px;
    height: 310px;
    // padding: 10px;
    display: flex;
    align-items: end;
    width: 100%;
    justify-items: flex-start;
    .image-title {
      box-shadow: 0px 5px 10px #00000080;
      border-radius: 10px;
      padding: 15px;
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      margin: 10px;
      width: 100%;
      color: $white;
      background-color: #000000ab;
      min-width: 200px;
    }
  }
}

.read-more {
  transition: all 0.2s;
  cursor: pointer;
  span {
    transition: margin 0.2s;
  }
}
.read-more:hover {
  color: $primary !important;

  span {
    margin-left: 5px;
  }
}

#clients {
  overflow-x: hidden !important;
}

input,
textarea {
  padding: 5px 10px !important;
  height: 35px;
  background-color: $black !important;
  border: 1px solid #fed17e !important;
  border-radius: 10px !important;
}

.cutom-upload {
  cursor: pointer;
  input {
    // visibility: hidden;
  }
  .show-input {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-right: none !important;
    box-shadow: none !important;
  }
  .input-group-text {
    background-color: transparent !important;
    // border: none;
    // border-radius: 10px !important;

    border-top-left-radius: 0px !important;
    border-left: none !important;
    border-color: $primary;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }
}

.button-with-icon {
}

.slider-show-all-item {
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
  div {
    transition: color 0.3s;
    color: $white;
  }
}
.slider-show-all-item:hover {
  div {
    color: $primary;
  }
}

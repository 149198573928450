//
// footer.scss
//

.footer {
  padding: 0px;
  background: $dark;
  height: 80vh;
  min-height: 50em;
  // background-position: center center;
  // background-size: cover;
  width: 100%;
  position: relative;

  #footer-float-form {
    position: absolute;
    top: 4%;
    left: 10%;
    width: 610px !important;
    z-index: 40;
    box-shadow: 0px 5px 10px #00000080;
    border-radius: 15px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: #000000cf;
    padding: 15px;
    padding-bottom: 1em;
    #footer-line {
      margin: 0px;
      top: 0;
      left: 0%;
      width: 100%;
      position: relative;
      height: 2px !important;
      opacity: 1 !important;
      background: transparent
        linear-gradient(
          90deg,
          #b07f00 0%,
          #bf8d34 12%,
          #fed17e 41%,
          #de9d00 74%,
          #d5b16c 100%
        )
        0% 0% no-repeat padding-box;
    }
  }
  @media (min-width: 200px) and (max-width: 768px) {
    #google-map-footer{
      height: 150% !important;
    }
    .footer-form-font {
      .btn-primary {
        border-radius: 5px !important;
      }

      input,
      textarea {
        border-radius: 5px !important;
        // display: none;
        padding-left: 1px !important;
        padding-top: 9px !important;
        font-size: 12px !important;
      }
    }
    .footer-logo {
      img {
        height: auto;
        width: 150px;
      }
    }
    #footer-line {
      margin-top: 10px !important;
      margin-bottom: 10px !important;
    }
    .footer-link-container {
      div {
        padding-left: 0px;
        padding-right: 0px;
        margin-top: 4px !important;
        margin-bottom: 4px !important;
        .footer-link {
          color: white !important;
        }
      }
    }
    height: 1500px;
    #footer-float-form {
      left: 2%;
      width: 95vw !important;
    }
    textarea {
      min-height: 10rem !important;
    }

    .footer-link {
      padding: 15px 0px !important;
    }
  }
  #footer-float-section {
    overflow: hidden;
    position: absolute;
    bottom: 4%;
    right: 2%;
    width: 96%;
    box-shadow: 0px 5px 10px #00000080;
    border-radius: 25px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: #000000cf;
    padding: 20px;
    #overlay {
      background: transparent url("../images/footer.png");
      background-position: center;
      background-size: auto 100%;
      position: absolute;
      background-repeat: no-repeat !important;
      bottom: 0%;
      width: 100%;
      height: 100%;
      left: 0;
      opacity: 0.6;
    }
    #footer-line {
      margin: 0px;
      top: 0;
      left: -50vw;
      width: 220vw;
      position: relative;
      height: 2px !important;
      opacity: 1 !important;
      background: transparent
        linear-gradient(
          90deg,
          #b07f00 0%,
          #bf8d34 12%,
          #fed17e 41%,
          #de9d00 74%,
          #d5b16c 100%
        )
        0% 0% no-repeat padding-box;
    }
    .footer-link {
      color: rgba($white, 0.6);
      transition: all 0.5s;
      &:hover {
        color: $white;
        // margin-top: -5px !important;
      }
    }
  }

  .footer-sub-menu {
    li {
      padding: 6px 0;
    }
  }
  .google-map-code {
    overflow-y: hidden;
    height: calc(100% - 250px);
    .map-control {
      background-color: #fff;
      border: 1px solid #ccc;
      box-shadow: 0 2px 2px rgba(33, 33, 33, 0.4);
      font-family: "Roboto", "sans-serif";
      margin: 10px;
      padding-right: 5px;
      /* Hide the control initially, to prevent it from appearing
               before the map loads. */
      display: none;
    }

    /* Display the control once it is inside the map. */
    #map .map-control {
      display: block;
    }

    .selector-control {
      font-size: 14px;
      line-height: 30px;
      vertical-align: baseline;
    }
  }
}

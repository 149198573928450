//
// menu.scss
//

.nav-item {
  .nav-link {
    display: inline-block;
    color: $dark;
    text-decoration: none;
  }
}
.navbar {
  .menu-social-icon {
    li {
      margin: 0 8px;
      .menu-social-link {
        color: $muted;
        transition: all 0.5s;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}
@media (min-width: 200px) and (max-width: $mobile-width) {
  .nav-sticky {
    .logo {
      div {
        .first {
          top: 1px !important;
          height: 50px;
        }
      }
    }
  }
  .logo {
    div {
      .first {
        top: 1px !important;
      }
    }
  }
}
.navbar-custom {
  padding: 14px 0px;
  width: 100%;
  border-radius: 0px;
  z-index: 999;
  margin-bottom: 0px;
  transition: all 0.5s ease-in-out;
  background-color: transparent;
  .logo {
    div {
      // display: none;
      position: relative;
      .first {
        transition: all 0.1s linear;
        position: relative;
        // background-color: black;
        top: 14px;
        // margin-toDp: 5px;
        z-index: 20;
      }
      .second {
        transition: all 0.3s linear;
        z-index: 10;
        left: 0;
        right: 0;
        opacity: 0;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
      }
    }
    .logo-dark {
      display: none;
    }
    .ligo-light {
      display: inline-block;
    }
  }

  .navbar-nav {
    li {
      min-width: 5rem;
      // text-align: center;
      a {
        line-height: 26px;
        color: $muted;
        font-size: 15px;
        font-weight: 400;
        transition: all 0.3s;
        background-color: transparent !important;
        padding: 0px 0;

        margin: 6px 0px;
      }
      .nav-link {
        padding-right: 0px;
        padding-left: 0px;
      }
    }
    li.active a,
    li a:hover,
    li a:active {
      color: $primary !important;
    }
    li.active a {
      border-bottom: 1px solid $primary;
    }
    &.menu-social-icon {
      .nav-item {
        .nav-link {
          margin: 0 10px !important;
        }
      }
    }
  }
  .navbar-toggles {
    padding: 0.25rem 0.75rem;
    font-size: 18px;
    background: 0 0;
    border: 1px solid transparent;
    color: $white;
    outline: 0;
  }
}

.navbar-toggler {
  font-size: 24px;
  margin-top: 5px;
  margin-bottom: 0px;
  color: $white;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background-color: transparent;
  border-color: $primary;
}

.menu-toggle {
  padding: 4.5px 10px !important;
  span {
    line-height: 27px;
  }
}

// NAVBAR LIGHT

.navbar-light {
  .navbar-nav {
    li {
      a {
        color: rgba($white, 0.6) !important;
      }
    }
    li.active a,
    li a:hover,
    li a:active {
      color: $white !important;
    }
  }
  .menu-social-icon {
    li {
      margin: 0 8px;
      .menu-social-link {
        color: rgba($white, 0.6) !important;
        transition: all 0.5s;
        &:hover {
          color: $white !important;
        }
      }
    }
  }
  .logo {
    .logo-dark {
      display: none !important;
    }
    .logo-light {
      display: inline-block !important;
    }
  }
}

/*---NAVBAR STICKY--*/
#nav-overly {
  // display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.8) !important;
  -webkit-backdrop-filter: blur(10px) !important;
  backdrop-filter: blur(10px) !important;
}
.nav-sticky {
  #backToTop {
    opacity: 1;
  }
  .logo {
    div {
      // display: none;
      position: relative;
      .first {
        // position: relative;
        transform: translateY(-3em);
        opacity: 0;

        // z-index: 20;
      }
      .second {
        opacity: 1;

        // z-index: 20;
      }
    }
  }
  &.navbar-custom {
    margin-top: 0px;
    /* UI Properties */
    // background-color: transparent;
    // mix-blend-mode: multiply;
    // backdrop-filter: blur(10px);
    // box-shadow: 0 0 10px 0 rgba($black, 0.06);
    opacity: 1;
    color: $black !important;
    .navbar-toggles {
      padding: 0.25rem 0.75rem;
      border: 1px solid transparent;
      outline: 0;
    }
    .navbar-nav {
      margin-top: 0px;
      li {
        a {
          color: $white; //darken($muted, 6%) !important;
        }
      }
      li.active a,
      li a:hover,
      li a:active {
        color: $primary !important;
      }
    }
  }

  .logo {
    .logo-dark {
      display: inline-block !important;
    }
    .logo-light {
      display: none !important;
    }
  }
}

.navbar-toggler {
  &:focus {
    outline: none;
  }
}

@media (min-width: 200px) and (max-width: 768px) {
  
  
  #navbarCollapse{
    height: 100vh;
    flex-direction: column;
    position: relative;
    top: -70px;
    z-index: 10;
  }
  .menu-logo-mobile-row{

    position: relative;
       z-index: 11;

  }
  
  .left-item-nav {
    padding-left: 0px;
    padding-right: 0px;
  }
  #navbar-navlist {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .navbar-custom {
    margin-top: 0px;
    // background-color: $bg !important;
    box-shadow: 0 3px 10px rgba($black, 0.08);

    color: $white !important;
    .navbar-nav {
      margin-top: 10px;
      li {
        a {
          transition: all 0.4s;
          color: $muted !important;
          margin: 0px;
        }
        &.active {
          a {
            border-color: transparent;
          }
        }
      }
      li.active a,
      li a:hover,
      li a:active {
        color: $primary !important;
      }
    }
    & > .container {
      width: 90%;
    }
    .logo {
      .logo-dark {
        display: inline-block !important;
      }
      .logo-light {
        display: none !important;
      }
    }
    &.navbar-light {
      .navbar-nav {
        li.active a,
        li a:hover,
        li a:active {
          color: $dark !important;
        }
      }
    }
  }
  .navbar {
    .menu-social-icon {
      li {
        margin: 0 8px;
        .menu-social-link {
          color: $muted !important;
          transition: all 0.5s;
          &:hover {
            color: $primary !important;
          }
        }
      }
    }
  }
  .nav-sticky {
    &.navbar-custom {
      .navbar-nav {
        margin-top: 10px;
      }
    }
  }
  .navbar-toggler {
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: $dark;
  }
  .navbar-collapse {
    padding: 0 20px;
    font-family: Raleway !important;
    .navbar-nav li a {
      font-size: 1.4em !important;
      font-weight: bolder;
      color: white !important;
      letter-spacing: 1px;
    }
    li {
      padding: 1rem;   
      margin-bottom: 2vh;
      text-align: left !important;
      .nav-link {
        color: white !important;
      }
    }
  }
  #nav-overly.extend {
    height: 100%;
  }
  #nav-overly {
    height: calc(4rem + 3px);
    top: -3px;
  }
  #nav-overly.active {
    background-color: rgba(0, 0, 0, 0.6) !important;
  }
  .navbar-collapse.show {
  }
  .navbar-light {
    .nav-item {
      .nav-link:after {
        background: $dark;
      }
    }
  }
}

#backToTop {
  position: fixed;
  z-index: 100;
  bottom: 2%;
  transition: all 0.3s;
  right: 2%;
  opacity: 0;
  svg {
    transition: all 0.4s;
    fill: $primary;
    opacity: 0.5;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
}

.nav-toggle-icon {
  left: 0;
  right: 0;
  margin: auto;
  // width: 100px;
  top: 6px;
  bottom: 0;
}
